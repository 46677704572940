import React from "react";

export function tf_module_ci() {
	return {
		date: "22 Mar 2024",
		title: "Automating Terraform modules validation and security scanning in CI/CD",
		description:
			"Modules is one of the most important Terraform feature because it allows us to create repeatable infrastructure with really tight configuration blazingly fast. Since modules are used in multiple infrastructures, it's important to guarantee that they follow best practices for better performance and security",
		style: ``,
		keywords: [
			"terraform module automation",
			"terrafomr module cicd",
			"terraform module test",
			"terraform module validation",
			"terraform module ci/cd",
		],
		body: (
			<React.Fragment>
				<div className="article-content">
					<p>
						Using Terraform modules can speed up the infrastructure
						building proccess and guarantee that configuration is
						consistant across all infrastructure resources. However,
						if your module is misconfigured or there are any
						security flaws, it means that all infrastructure also
						have the same problems too. That alone makes it
						paramount to have Terraform modules tested and scanned
						for security issues.
					</p>
					<h1>The workflow</h1>
					<p>
						Using available open-source tools and native Terraform
						commands, we can build{" "}
						<a href="https://github.com/joao-zanutto/shared-workflows/blob/main/.github/workflows/tf-ci.yaml">
							this workflow
						</a>{" "}
						that I use for all of my terraform projects. It can be
						ported to any CI/CD tooling and will guarantee that the
						Terraform code is secure and follows best practices.{" "}
						<a href="https://github.com/joao-zanutto/terraform-aws-ecs-cluster">
							Here is an example project
						</a>{" "}
						that utilizes this workflow to validate, scan,
						automatically format the code and generate documentation
						and release in GitHub. The step by step proccess is as
						follows:
					</p>
					<ol>
						<li>
							<b>Validate</b>: using native terraform command
							<code> terraform validate</code>
						</li>
						<li>
							<b>Lint</b>: using open source{" "}
							<code>
								<a href="https://github.com/terraform-linters/tflint">
									tflint
								</a>
							</code>
						</li>
						<li>
							<b>Security Scanning</b>: using open source{" "}
							<code>
								<a href="https://github.com/bridgecrewio/checkov">
									checkov
								</a>
							</code>
						</li>
						<li>
							<b>Format</b>: using native terraform command{" "}
							<code> terraform fmt</code>
						</li>
						<li>
							<b>Generate Docs</b>: using open source{" "}
							<code>
								<a href="https://github.com/terraform-docs/terraform-docs">
									terraform-docs
								</a>
							</code>
						</li>
						<li>
							<b>Generate Release</b>: using open source{" "}
							<code>
								<a href="https://github.com/google-github-actions/release-please-action">
									release-please
								</a>
							</code>
						</li>
					</ol>
					<p>
						Now, let's go through each of the steps in the workflow
						to better understand what each of them do
					</p>
					<h3>Validate</h3>
					<p>
						As the name implies, the validation proccess guarantee
						that the code is valid and running{" "}
						<code>terraform apply</code> on it won't error out
						because of a syntax issue.
					</p>
					<h3>Lint</h3>
					<p>
						Linting is the proccess of automatically checking for
						errors, bugs, and progammatic and stylistic issues in
						code. tflint is an open-source linter that has features
						enabled by plugins, and can be run both via CLI or in a
						CI/CD pipeline.
					</p>
					<h3>Security Scanning</h3>
					<p>
						There are multiple tools available for scanning
						Terraform code for security vulnerabilities. But taking
						into account the availability of an open-source tool and
						the functionalities available, my choice for my
						open-source modules was <code>checkov</code>, which has
						most coverages for known vulnerabilities and has a great
						integration with GitHub.
					</p>
					<h3>Format</h3>
					<p>
						<code>terraform fmt</code> command is used to rewrite
						Terraform code to canonical format and style, making
						sure that the code follows styling conventions and
						ensuring consistency across all the Terraform code.
					</p>
					<h3>Generate Docs</h3>
					<p>
						Terraform module docummentation often follows a
						pre-defined pattern and have some important sections
						that need to be included, like Requirements, Providers,
						Inputs and Outputs. Writting this docummentation can be
						facilitated by using the <code>terraform-docs</code>{" "}
						utility that will generate docummentation in many
						different output formats and allows for customization of
						the generated docummentation.
					</p>
					<h3>Generate Release</h3>
					<p>
						Releasing versions of Terraform modules is important to
						pin down modifications specific version tags and avoid
						generating drifts in infrastructure. Using a tool like{" "}
						<code>release-please</code> is great to facilitate the
						proccess of releasing these versions and provide an nice
						and automated way to manage the releasing proccess
						through GitHub.
					</p>
				</div>
			</React.Fragment>
		),
	};
}
