import React from "react";

export function texResume() {
	return {
		date: "18 May 2024",
		title: "How I programmed my Resume and now build it with CI/CD with every new commit",
		description:
			'I added my resume to my portfolio GitHub repostory and automated it\'s publishing with GitHub Actions and GitHub pages, guaranteeing that the "download resume" button in my portfolio is always pointing to the most recent revision ',
		keywords: [
			"resume",
			"resume build",
			"program resume",
			"resume automation",
			"resume code",
			"resume tex",
			"resume latex",
		],
		body: (
			<React.Fragment>
				<div className="article-content">
					<p>
						Having used LaTeX to initially develop my resume in
						Overleaf, which is a platform that can be used to build
						pdf documments our of .tex templates, I realized I had
						the oportunity to apply my automation skills by adding
						it to my portfolio repository and creating a GitHub
						Actions workflow to build and publish it to GitHub Pages
						based on new changes.
					</p>
					<h1>Checking the code</h1>
					<p>
						You can check my{" "}
						<a href="https://github.com/joao-zanutto/joao-zanutto.github.com">
							portfolio repo
						</a>{" "}
						for the whole code, but you should only focus in the
						last two files:
					</p>
					<ul>
						<li> React App that renders this website</li>
						<li>
							<a href="https://github.com/joao-zanutto/joao-zanutto.github.com/blob/main/resume.tex">
								Readme in .tex
							</a>
						</li>
						<li>
							<a href="https://github.com/joao-zanutto/joao-zanutto.github.com/blob/main/.github/workflows/publish.yml">
								GitHub Actions workflow{" "}
							</a>
							that builds the React App and .tex file in parallel
							and publishes both to GitHub Pages
						</li>
					</ul>
					<p>
						We won't focus on the .tex file as it can be reused just
						by replacing the user data, and many more templates can
						be found online and tailored to one's preference. We'll
						be focusing in understanding the github pages workflow
						logic, which should also be easy to understand.
					</p>
					<h2>GitHub Action Logic</h2>
					<p>The workflow file is divided in the following jobs</p>
					<code>
						<ul>
							<li>build - runs in parallel</li>
							<ul>
								<li>react</li>
								<li>resume</li>
							</ul>
							<li>
								package - gather output files together -- runs
								once both builds have finished
							</li>
							<li>publish</li>
						</ul>
					</code>

					<p>
						Based on that, it should be easy to port this setup to
						any other language or framework that builds a static
						website and have it also build a .tex file and publish
						it to the same storage provider.
					</p>
					<p>
						If you want to use this GitHub Action, you just need to
						replace the react build with your own action for
						building your website.
					</p>
				</div>
			</React.Fragment>
		),
	};
}
