const INFO = {
	main: {
		title: "Joao Zanutto - Senior DevOps Engineer",
		name: "Joao Zanutto",
		email: "jpberno@gmail.com",
		logo: "logo.webp",
	},

	socials: {
		resume: "./resume.pdf",
		twitter: "https://twitter.com/",
		github: "https://github.com/joao-zanutto",
		linkedin: "https://linkedin.com/",
		stackoverflow: "https://stackoverflow.com/users/4100089/pedro-zanutto",
	},

	homepage: {
		title: "Senior DevOps Engineer",
		description:
			"I'm Joao, I work as a DevOps Engineer and I help companies deliver secure and scalable software in a resilient and fast manner by provisioning cloud infrastructure, developing automation and deployment pipelines, and by configuring monitoring tools that let us know when something is not right. I have extensive experience in the AWS cloud and in DevOps best practices, focusing primarily on communication, collaboration, automation and observability pillars",
	},

	about: {
		title: '"Learn everyday."',
		description: [
			"Joao is a passionated Software Engineer and tech enthusiast. Having studied in one of the greatest universities in Latin America (University of Sao Paulo), he obtained the title of Bachelor in Computer Science from one of the greatest technology institutes in the world (ICMC-USP).",
			"With over 5 years of experience in the IT industry, Joao currently occupies the position of a Senior DevOps Engineer, being responsible for designing and developing Cloud Architectures. He does so by implementing, maintaining and automating infrastructure with multiple IaC tools and scripts.",
			"Other responsabilities include assesing and tracking security vulnerabilities, responding to incidents and aiding on it's resolution, planning disaster recovery and backup activities and overall cost optimization while following industry best practices.",
			"Lastly, Joao is very found of open source software and is always looking forward to contribute and elavate the technical discussion.",
		],
	},

	articles: {
		title: "Articles",
		description:
			"In these pages you will find technical guides, tips on open source solutions and discussions on other topics",
	},

	projects: [
		{
			title: "github-shell-bot",
			description:
				"ChatOps Bot that runs Shell scripts based on comments in GitHub Pull Requests",
			logo: ["./javascript.svg", "./bash.svg", "./github.svg"],
			linkText: "View Project",
			link: "https://github.com/joao-zanutto/github-shell-bot",
		},
		{
			title: "terraform-aws-ecs-cluster",
			description:
				"Terraform module used to deploy an AWS ECS Cluster using EC2 instances as capacity provider.",
			logo: ["./terraform.svg", "./aws.svg"],
			linkText: "View Project",
			link: "https://github.com/joao-zanutto/terraform-aws-ecs-cluster",
		},

		{
			title: "terraform-k8s-monitoring-module",
			description:
				"Terraform module used to deploy Prometheus and Grafana stack in Kubernetes",
			logo: [
				"./terraform.svg",
				"./kubernetes.svg",
				"./prometheus.svg",
				"./grafana.svg",
			],
			linkText: "View Project",
			link: "https://github.com/joao-zanutto/terraform-k8s-monitoring-module",
		},

		{
			title: "shared-workflows",
			description:
				"Repository that contains GitHub Actions workflows used in multiple Terraform modules repos",
			logo: ["./actions.svg", "./bash.svg"],
			linkText: "View Project",
			link: "https://github.com/joao-zanutto/shared-workflows",
		},

		{
			title: "portfolio",
			description:
				"Repository holding this website. Adapted from an open on an open-source project with a couple of changes made by myself",
			logo: ["./javascript.svg", "./react.svg"],
			linkText: "View Project",
			link: "https://github.com/joao-zanutto/portfolio",
		},
	],
};

export default INFO;
