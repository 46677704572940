import React from "react";

export function greenmask() {
	return {
		date: "06 Apr 2024",
		title: "Validate database migrations with masked sentive data using production dumps",
		description:
			"Greenmask is a platform agnostic tool to generate Postgres data dumps while masking sensitive data. It uses native pd_dump and pg_restore to manipulate data, so it just works with any current tooling that you may use. It can also run on a Docker container and can be used to extract and mask your sesitive real data to evaluate whether your migrations will break in production.",
		keywords: [
			"masked postgres dump",
			"masked database dump",
			"postgres dump masked sensitive data",
			"database dump masked sensitive data",
			"rds dump masked sensitive data",
			"postgres dump masked data",
			"database dump masekd data",
			"rds dump masked data",
		],
		body: (
			<React.Fragment>
				<div className="article-content">
					<p>
						If you are constantly having issues with database
						migrations when deploying to production, then you
						definetely want to hear about{" "}
						<a href="https://github.com/GreenmaskIO/greenmask">
							Greenmask
						</a>
						. It is is a tool to generate anonymized Postgres dumps
						and restoration that also supports S3-like storage in
						the cloud.
					</p>
					<h1>How to validate migrations with Greenmask</h1>
					<p>To validate database migrations using production data you will tipicaly need to:</p>
					<ol>
						<li>
							Run Greenmask inside your private network to generate and upload your masked dump file to an S3 compatible object storage
						</li>
						<li>
							Configure Greenmask in your local environment or CI/CD pipeline to download and apply the dump file to a new postgres database
						</li>
						<li>
							Apply the newest schema migration to the new database
						</li>
						<li>
							Bring up your application and validate that the new migrations haven't anything
						</li>
						<li>
							Run your unit/integration tests as usual to guarantee that your new migrations haven't broke your tests
						</li>
					</ol>
				</div>
			</React.Fragment>
		),
	};
}
