import React from "react";

export function githubBot() {
	return {
		date: "22 May 2024",
		title: "I created a bot to run shell scripts from GitHub Pull Requests",
		description: "",
		keywords: [],
		body: (
			<React.Fragment>
				<div className="article-content"></div>
			</React.Fragment>
		),
	};
}
